














import { Component, Prop, Vue } from "vue-property-decorator";
import Header from "@/components/common/Header.vue"; // @ is an alias to /src
import Footer from "@/components/common/Footer.vue"; // @ is an alias to /src

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class Layout extends Vue {
  @Prop() readonly hbgsrc!: string;
  @Prop({ default: true }) readonly bg!: boolean;
}
