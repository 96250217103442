
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class WordOnPic extends Vue {
  @Prop({ default: "" }) readonly bg!: string;
  @Prop({ default: "#fff" }) readonly color!: string;
  @Prop({ default: "" }) readonly path!: string;

  navigate() {
    this.$router.push({
      path: this.path,
    });
  }
}
